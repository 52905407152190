import React from 'react'
import SEO from '../components/App/SEO'
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import moment from 'moment';
import { graphql, StaticQuery } from 'gatsby';

export default function Template() {
  return (
    <StaticQuery
    query={graphql`
    query NewsIndexQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "news" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              date
              templateKey
              image
              url
              title
              description
            }
          }
        }
      }
      site {
        siteMetadata {
          env
        }
      }
    }
    `}
    render={data => (
      <Layout env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO
          postTitle="News and Reviews | BlueMail iOS, Android, Windows, Linux Email App"
          postDescription="BlueMail in the News and Media. See what others have to say about Blue Mail, the best email app for Android, iOS, Windows, Linux. Try us today!"
          postImage=""
          postURL="news"
          postSEO
        />
      <div className="news-container bg-nav">
      <div className="container">        
        <div className="row">
          <div
            className="col-12"
            style={{ textAlign: 'center', padding: '50px 0' }}
          >
            <h1>BlueMail in the News & Media</h1>
          </div>
        </div>


        <div className="articles-grid">
          {data && data.allMarkdownRemark && data.allMarkdownRemark.edges && data.allMarkdownRemark.edges
            .sort(
              (a, b) =>
                new Date(b.node.frontmatter.date) -
                new Date(a.node.frontmatter.date)
            )
            .map((post, i) => (
              <div key={post.node.id} className="articles-item">
                <a href={post.node.frontmatter.url}>
                  <div>
                    <div style={{height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(249, 249, 249, .6)'}}>
                    <img className="news-img" target="_blank"
                      src={
                        // data.site.siteMetadata.imagesPrefix +
                        post.node.frontmatter.image}
                      alt={post.node.frontmatter.title}
                    />
                    </div>
                    <h4>{post.node.frontmatter.title}</h4>
                    {/* <p>{moment(post.node.frontmatter.date).format('ll')}</p> */}
                  </div>
                  
                  
                  <div>
                  
                  <div className="news-description">
                    {post.node.frontmatter.description}
                  </div>
                  </div>
                  <div style={{width: '100%', lineHeight: '141.5%'}}>
                  <a href={post.node.frontmatter.url} className="article-link">
                    Read more</a>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
      </div>
      <Footer />
      </Layout>
    )}
  />
    
  )
}


